@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../styles/variables';

.half-page-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;

  @include media-breakpoint-down(sm) {
    width: 100%;
    position: relative;
    height: auto;
    max-height: 50vh;
  }
}
