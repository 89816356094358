@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../styles/variables';

.page-block-body {
  blockquote {
    padding: 0 4rem;
    color: $primary;
    font-weight: bold;
    margin: $spacer * 3 0;
    font-size: 1.5625rem;
    position: relative;

    &::before,
    &::after {
      content: '“';
      font-family: 'Montserrat', sans-serif;
      color: $secondary;
      display: block;
      position: absolute;
      height: 20px;
      width: 20px;
      font-size: 56px;
      line-height: 56px;
    }

    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      content: '”';
      bottom: 0;
      right: 8px;
    }
  }
}
