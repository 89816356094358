@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.bubble-background-section {
  position: relative;

  &__front {
    position: relative;
    z-index: 10;
  }

  &__back {
    pointer-events: none;
    touch-action: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 0;

    .bubble {
      border-radius: 50%;
      position: absolute;
      display: block;
      opacity: 0.21;
    }

    .bubble-1 {
      display: block;
      border-radius: 50%;
      top: -240px;
      left: 0;
      width: 800px;
      height: 800px;
      background: #fff 0 0 no-repeat padding-box;

      @include media-breakpoint-down(md) {
        top: -290px;
        left: -470px;
      }
    }

    .bubble-2 {
      top: -149px;
      left: 853px;
      width: 857px;
      height: 926px;
      background:
        transparent
        radial-gradient(closest-side at 50% 50%, #fff 0%, #fff0 100%) 0% 0%
        no-repeat padding-box;
      opacity: 0.8;
    }

    .bubble-3 {
      top: 309px;
      right: -131px;
      width: 512px;
      height: 511px;
      background: #fff 0% 0% no-repeat padding-box;
    }
  }
}
