.gos-status-section {
  transition: all 0.3s ease;
}

.gos-status-button {
  border-radius: 16px;
  text-transform: uppercase;
  min-width: 190px;
  max-width: 190px;
  line-height: 16px;

  img {
    width: 40px;
    margin-right: 10px;
    display: block;
  }
}

.gos-status-image {
  height: 192px;
  width: 168px;
  object-fit: cover;
}
