@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../styles/variables';

.main-card {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.second-bar {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;

  @include media-breakpoint-down(xs) {
    border-radius: 0 !important;
  }
}
