.link-buttons {
  border-radius: 10px;
  padding: 0.5rem;
  max-width: 200px;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }
}
