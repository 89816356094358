@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.full-width-image-wrapper {
  display: block;

  video {
    display: block;
    max-height: 80vh;
    object-fit: contain;
  }
}
