@keyframes slideDown {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDownDelayed {
  0% {
    transform: translateY(-30%);
    opacity: 0;
  }

  50% {
    transform: translateY(-30%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-down {
  animation: slideDown 1s ease forwards;
}

.slide-down-delay {
  animation: slideDownDelayed 1.8s ease forwards;
}

.page-banner {
  position: relative;
  background-size: cover;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
  }

  :global {
    .container {
      position: relative;
      z-index: 10;
    }
  }
}

.page-black-background-campaign {
  height: calc(100vh - 93px);
}
