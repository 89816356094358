.supportive-messages-block {
  :global {
    .supportive-message-speech-bubble {
      opacity: 0.4;
      transform: scale(0.9);
      will-change: opacity, transform;
      transition: all 0.2s ease;
    }

    .slick-current {
      .supportive-message-speech-bubble {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}

.heading::after {
  margin-left: auto;
  margin-right: auto;
}

.button {
  @media (max-width: 768px) {
    min-width: 217px;
  }
}
