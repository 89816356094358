.page-banner {
  position: relative;
  background-size: cover;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 150px;
  margin-bottom: -150px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
  }

  :global {
    .container {
      position: relative;
      z-index: 10;
    }
  }
}

.modal-video {
  :global {
    .modal-content {
      background: none;
      border: none;
    }
  }
}
