@import '../../../styles/variables';

.quote-on-media-content {
  position: relative;
  background-size: cover;
  background-position: center;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    touch-action: none;
    background: rgba(0, 0, 0, 0.2);
  }
}

.quote-on-media-body {
  padding: $spacer * 2;
  position: relative;
  font-weight: 600;
}
