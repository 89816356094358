@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../styles/variables';

.page-banner-image {
  max-height: 416px;
  display: block;
  margin: auto;
}

.header {
  @include media-breakpoint-down(md) {
    min-height: 25vh;
  }
}
