.current-appeals-compact-block-slider {
  display: flex;
  flex-direction: column;

  :global {
    .slick-list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .slick-track {
        flex-grow: 1;

        .slick-slide {
          display: flex;
          flex-direction: column;

          > div {
            flex-grow: 1;
            display: flex;

            > div {
              display: inline-flex !important;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
