@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.life-saver-row {
  min-height: 560px;

  @include media-breakpoint-down(sm) {
    padding-top: 30px;
    min-height: auto;
    overflow-x: hidden;
  }
}

.life-saver-circle {
  position: absolute !important;
  right: 50%;
  bottom: 0;

  @include media-breakpoint-down(sm) {
    position: relative !important;
    right: unset;
    bottom: unset;
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    max-width: 85vw;
    max-height: 85vw;
  }
}

.life-saver-circle-wrapper {
  @include media-breakpoint-down(sm) {
    width: 300px;
    height: 300px;
    max-width: 85vw;
    max-height: 85vw;
  }
}
