.container {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.pageLink::after {
  content: '.';
}

.pageLink,
.previousLink,
.nextLink {
  text-decoration: underline !important;
}

.activeLink {
  text-decoration: none !important;
}

.disabled {
  .nextLink,
  .previousLink,
  .activeLink {
    text-decoration: none !important;
    opacity: 0.5;
  }
}
