.speech-bubble {
  position: relative;
  padding-bottom: 20px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 10%;
    bottom: 0;
    width: 0;
    height: 0;
    border: solid 10px transparent;
    border-top-color: white;
    border-left-color: white;
    z-index: 10;
  }

  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: white;
  }

  *::-webkit-scrollbar {
    border-radius: 0 10px 10px 0;
    background-color: white;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--light-grey);
  }
}
