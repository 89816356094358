@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../styles/variables';

.button {
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    min-width: 100px;
  }
}
